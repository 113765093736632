<template>
  <div
    v-if="edit"
    :class="type === 'add' ? 'pb-10' : 'py-10'"
    class="md:full w-full mx-auto text-left"
  >
    <div class="mb-0">
      <h2
        :class="type === 'add' ? ' text-center' : 'text-left'"
        class="px-3 md:px-12 lg:px-8 xl:px-10 text-headerText font-medium text-lg md:text-2xl"
      >
        {{ type === "edit" ? " Edit Job History" : "Add Job" }}
      </h2>
      <hr class="my-4 border-0.5 border-dividerColor text-dividerColor" />
    </div>
    <form
      @submit.prevent="UpdateJobHistory(true)"
      autocomplete="on"
      class="flex flex-col gap-y-2 px-3 md:px-12 lg:px-8 xl:px-10"
    >
      <!-- <label for="organization" class="text-dark-600 text-sm font-medium"
        >Company/Organization
      </label> -->
      <easiTextInput
        placeholder="Company/Organization"
        type="text"
        name="organization"
        class="mt-1 mb-3"
        v-model="args.companyName"
        required
      ></easiTextInput>

      <!-- <label for="title" class="text-dark-600 text-sm font-medium"
        >Job Title
      </label> -->
      <easiTextInput
        placeholder="Job Title"
        type="text"
        name="title"
        class="mt-1 mb-5"
        v-model="args.jobTitle"
        required
      ></easiTextInput>

      <label for="start" class="mt-2 text-dark-600 text-sm font-medium"
        >Start Date
      </label>
      <input
        class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block appearance-none rounded-2xl mb-2"
        type="date"
        name="start"
        id="start"
        :max="today"
        v-model="args.startDate"
        required
      />

      <label for="end" class="text-dark-600 text-sm font-medium"
        >End Date
      </label>
      <input
        class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block appearance-none rounded-2xl mb-3"
        type="date"
        name="end"
        id="end"
        :max="today"
        :min="args.startDate"
        required
        v-model="args.endDate"
      />

      <!-- <label for="link" class="text-dark-600 text-sm font-medium"
        >Job Description
      </label> -->
      <easiTextArea
        class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block appearance-none rounded-2xl mb-6"
        name="address"
        placeholder="Job Description"
        id="address"
        cols="30"
        rows="5"
        required
        v-model="args.description"
      ></easiTextArea>

      <div
        v-if="type === 'edit'"
        class="w-full my-3 flex flex-col md:flex-row w-full justify-center justify-items-center place-items-center gap-5 items-center"
      >
        <easiButton
          @click="edit = false"
          type="button"
          variant="outlined"
          class="bg-white w-80 md:w-52"
        >
          Cancel
        </easiButton>
        <easiButton
          :loading="loading.edit"
          type="submit"
          class="w-80 md:w-52"
          :class="loading.edit ? 'w-80 md:w-52' : 'w-52 '"
        >
          <span class="text-sm"> Update </span>
        </easiButton>
      </div>
      <!-- ////// Add Buttons ///// -->
      <div
        v-if="type === 'add'"
        class="w-full my-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
      >
        <easiButton
          type="button"
          @click="save"
          :loading="loading.save"
          color="primary"
          variant="outlined"
          class="w-80 md:w-52"
        >
          <span class="text-sm"> Save And Add More </span>
        </easiButton>
        <easiButton
          :loading="loading.add"
          type="submit"
          color="primary"
          class="w-80 md:w-52"
        >
          <span class="text-sm"> Submit </span>
        </easiButton>
      </div>
      <p
        v-if="type === 'edit'"
        @click="deleteWarning = true"
        class="cursor-pointer text-center text-sm font-medium text-dark-600 my-4"
      >
        Delete Job History
      </p>
    </form>
    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span
          >Job history
          {{ type === "edit" ? "updated" : "added" }} successfully</span
        >
      </template>
    </easiSuccess>

    <easiWarn v-if="deleteWarning" @close="deleteWarning = false">
      <template v-slot:message>
        <span>Are you sure you want to delete this job history?</span>
      </template>
      <template v-slot:action>
        <div class="flex flex-col items-center gap-4">
          <div class="flex justify-center items-center w-full gap-x-4 mt-6">
            <easiButton
              class="w-40 min-w-full"
              @click="deleteWarning = false"
              variant="outlined"
              >Cancel</easiButton
            >
            <easiButton
              class="w-40 min-w-full"
              :loading="loading.delete"
              @click="deleteJobHistory"
              >Yes</easiButton
            >
          </div>
        </div>
      </template>
    </easiWarn>
  </div>

  <ViewJobHistory @job="update($event)" v-else />
</template>

<script setup>
import picIcon from "@/assets/icons/pic.svg";
import ViewJobHistory from "@/components/Employee/View/Job/JobHistory";

import { useRoute } from "vue-router";
import {
  ref,
  reactive,
  nextTick,
  watch,
  computed,
  onMounted,
  onUnmounted,
} from "vue";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";
import { getFragmentFromSelection } from "@apollo/client/utilities";

const { resetObj, processDate } = helperFunctions;

const store = useDataStore();
const { query, mutate } = store;
const { listEmployees, getAllEmployees } = storeToRefs(store);
const toast = useToast();
const today = ref("");

today.value = processDate();

const route = useRoute();
const props = defineProps({
  type: {
    type: String,
    default: "edit",
  },
});
const emit = defineEmits(["complete"]);
const employee = computed(() => store.getEmployeeById(route.params.id));

const loading = ref({
  add: false,
  edit: false,
  save: false,
  delete: false,
});

let updateSuccess = ref(false);
const deleteWarning = ref(false);

const edit = ref(false);
const argsArray = ref([]);

const payload = reactive({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: { firstName: "ASC" },
  },
  filter: [],
});

const args = reactive({
  _id: null,
  companyName: "",
  jobTitle: "",
  startDate: "",
  endDate: "",
  description: "",
});

const profileArgsFromStore = computed(() => store.profileData);

const employeeId = route.params.id;

async function queryEmployees() {
  await query({
    endpoint: "ListEmployees",
    payload: payload,
    service: "EMP",
    storeKey: "listEmployees",
  });
}

// function assignValues() {
//   const keys = Object.keys(employees[0].profile);
//   const argkeys = Object.keys(args);
//   // newObj = keys.map((k) => {
//   //   argkeys.map((ak) => {
//   //     if (k === ak) {
//   //       args[ak] = employees[k];
//   //     }
//   //   });
//   // });
//   for (let k of keys) {
//     for (let ak of argkeys) {
//       if (k === ak) {
//         args[ak] = employees[0].profile[k];
//       }
//     }
//   }
//   console.log(args);
// }
function update(event) {
  edit.value = true;
  const arr = Object.keys(event);
  const assign = arr.map((key) => {
    args[key] = event[key];
    return event;
  });
  delete args["__typename"];
}
function addMore(arg) {
  const newObj = Object.assign({}, args);

  argsArray.value.push(newObj);

  store.$patch((state) => {
    state.profileArr.push(newObj);
  });

  return argsArray.value;
}

async function save() {
  addMore(args);
  await UpdateJobHistory(false);

  // window.location.reload();
}
async function UpdateJobHistory(final) {
  if (final) {
    loading.value[props.type] = true;
    addMore(args);
  } else {
    loading.value.save = true;
  }

  try {
    let res = await mutate({
      endpoint: "UpdateJobHistory",
      data: {
        employeeId,
        input: profileArgsFromStore.value,
      },
      service: "EMP",
    });

    if (res[res.length - 1].companyName.length) {
      await queryEmployees();
      loading.value[props.type] = false;
      loading.value.save = false;
      // toast.success("Job history Updated Successfuly");
      if (final) {
        updateSuccess.value = true;
        setTimeout(() => {
          updateSuccess.value = false;
          edit.value = false;
          emit("complete", true);
          // window.location.reload();
        }, 500);
      } else {
        toast.success("Details saved!");
      }
      // args = resetObj(args, "args");
      Object.assign(args, resetObj(args, "args"));
      store.$patch({
        profileArr: [],
      });
    } else {
      loading.value[props.type] = false;
      loading.value.save = false;
    }
  } catch (e) {
    loading.value[props.type] = false;
    loading.value.save = false;
    console.log(e);
    args = resetObj(args, "args");
    store.$patch({
      profileArr: [],
    });
  }
}

queryEmployees();

// args.id=
// args.companyName=
// args.jobTitle=
// args.startDate=
// args.endDate=
// args.description=

// assignValues();

const hide = ref(true);
let message = ref("");
let fileName = ref("");
let fileSize = ref("");

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

// let url = ref("");
function previewFiles(e) {
  const file = e.target.files[0];

  const allowedTypes = ["image/png", "image/gif", "image/jpeg"];
  if (!allowedTypes.includes(file.type)) {
    message.value = "File type is wrong!!";
    throw new Error(message.value);
  }
  if (file.size > 10000000) {
    message.value = "Too large, max size allowed is 10MB";
    throw new Error(message.value);
  }
  const { name, size } = file;
  fileName.value = name;

  fileSize.value = formatBytes(size);
  let reader = new FileReader();

  reader.onloadend = (e) => {
    // data url
    url.value = e.target.result;
    console.log(e.target.result, "K");
  };
  reader.readAsDataURL(file);
  // url = URL.createObjectURL(file);
}

onMounted(async () => {
  props.type === "add" ? (edit.value = true) : (edit.value = false);
  await queryEmployees();
  store.$patch({
    profileArr: [],
  });
});

onUnmounted(() => {
  store.$patch({
    profileArr: [],
  });
});

async function deleteJobHistory() {
  loading.value.delete = true;
  // let URL = await addMore(args);

  // addMore(args);
  const payload = {
    employeeId,
    jobHistoryId: args._id,
  };
  try {
    let res = await mutate({
      endpoint: "DeleteJobHistory",
      data: payload,
      service: "EMP",
    });
    if (res) {
      // toast.success("Education History Updated Successfuly");
      updateSuccess.value = true;
      deleteWarning.value = false;
      await queryEmployees();

      setTimeout(() => {
        updateSuccess.value = false;
        edit.value = false;
        emit("complete", true);
        // window.location.reload();
      }, 500);
    } else {
      loading.value.delete = false;
    }
  } catch (e) {
    toast.error(e.message);
    console.log(e);
  } finally {
    loading.value.delete = false;
  }
}
</script>

<style></style>
