<template>
  <div class="p-6 pt-10 md:p-8 md:pt-12 flex items-center justify-between">
    <span class="md:text-3xl text-xl text-dark-800 font-bold md:font-medium"
      >Job History List</span
    >
    <easiButton
      v-if="!fromArchived"
      @click="showAdd = true"
      variant="outlined"
      size="small"
      color="primary"
      class="flex items-center gap-2 mt-2 md:mt-0 border-primary rounded-lg"
    >
      <img class="w-3" src="@/assets/icons/plus.svg" alt="" />
      <span class="text-sm">Add Job History</span>
    </easiButton>
  </div>
  <hr class="text-dark-100" />
  <div
    v-if="jobHistory && jobHistory.length"
    class="p-6 md:p-8 flex flex-col gap-6 overflow-y-auto"
    style="height: 500px"
  >
    <div
      v-for="(item, i) in jobHistory"
      :key="i"
      class="flex flex-col gap-4 border-b border-dark-100 last:border-0 pb-6"
    >
      <div class="flex items-start justify-between">
        <div class="flex flex-col">
          <span class="text-sm text-dark-500">Company</span>
          <span class="text-base text-dark-800 font-medium">{{
            item.companyName
          }}</span>
        </div>
        <easiButton
          v-if="route.path.includes('edit')"
          @click="updateId(item)"
          size="small"
          variant="outlined"
          class=""
        >
          <span class="flex items-center text-base gap-3">
            Edit
            <img class="w-4" src="@/assets/icons/edit2.svg" alt="" />
          </span>
        </easiButton>
      </div>

      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Job Title</span>
        <span class="text-base text-dark-800 font-medium">{{
          item.jobTitle
        }}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Start Date</span>
        <span class="text-base text-dark-800 font-medium">{{
          formatDateString(item.startDate)
        }}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-sm text-dark-500">End Date</span>
        <span class="text-base text-dark-800 font-medium">{{
          formatDateString(item.endDate)
        }}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Job Description</span>
        <span class="text-base text-dark-800 font-medium">{{
          item.description
        }}</span>
      </div>
    </div>
  </div>
  <div v-else class="my-8">
    <easiEmptyState text="No Job History added" />
  </div>

  <easiModal :isBorder="false" v-if="showAdd" @close="showAdd = false">
    <JobHistoryAdd type="add" @complete="showAdd = false" />
  </easiModal>
</template>

<script setup>
import JobHistoryAdd from "@/components/Employee/Edit/Job/JobHistory.vue";
import { reactive, ref, computed } from "@vue/reactivity";
import { useRoute } from "vue-router";
import { useDataStore } from "@/stores/data.js";
import { helperFunctions } from "../../../../composable/helperFunctions";

const { formatDateString } = helperFunctions;

const props = defineProps({
  type: {
    type: String,
    default: "view",
  },
});
const emit = defineEmits(["job"]);

const store = useDataStore();
const route = useRoute();

const showAdd = ref(false);

const fromArchived = computed(() => store.getViewFromArchived);

const employee = computed(() =>
  fromArchived.value
    ? store.getArchivedEmployeeById(route.params.id)
    : store.getEmployeeById(route.params.id)
);
console.log(employee);

const jobHistory = computed(() => employee && employee.value.jobHistory);
function updateId(obj) {
  emit("job", obj);
}

const display = (arg) => {
  return arg ? arg : "N/A";
};
</script>

<style></style>
